<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
  >
    <button class="modal__close" @click="close">
      <font-awesome-icon icon="close"></font-awesome-icon>
    </button>
    <fieldset>
      <legend>
        {{ event.title }}
      </legend>
      <!-- <label class="modal__content"><b>ID: </b>{{ event.id }}</label>
      <br /> -->
      <label class="modal__content"><b>Začátek: </b>{{ start }}</label
      ><br />
      <label class="modal__content"><b>Konec: </b>{{ end }}</label>
      <br />
      <label class="modal__content"><b>Kurt: </b>{{ court }}</label>
      <br />
      <FormKit
        type="button"
        label="Zrušení rezervace"
        @click="showCodeInput = !showCodeInput"
        id="cancel"
      />
      <!-- <button id="removeButton" @click="showCodeInput = !showCodeInput">
        Zrušení rezervace
      </button> -->
      <div v-if="showCodeInput">
        <FormKit
          type="form"
          submit-label="Zrušit"
          :submit-attrs="{
            id: 'delete',
          }"
          @submit="deleteEvent"
          :incomplete-message="false"
        >
          <FormKit
            type="password"
            label="ID Rezervace"
            v-model="password"
            validation="required"
            :validation-messages="{
              required: 'Zadejte ID!',
            }"
          />
        </FormKit>
        <!-- <label for="code">Zadejte ID:</label>
        <input id="code" v-model="code" />
        <button @click="submitCode">Potvrdit</button> -->
      </div>
    </fieldset>
    <!-- <fieldset>
        <legend>Přidat událost:</legend>
        <input type="text" v-model="title" />
        <input type="date" v-model="start" />
        <input type="date" v-model="end" />
      </fieldset> -->
  </vue-final-modal>
</template>
<script>
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    // toast.success("Rezervace vytvořena!", {
    //   icon: "fas fa-check",
    // });
    return { toast };
  },
  data() {
    return {
      title: "",
      email: "",
      start: {},
      end: {},
      showCodeInput: false,
      code: "",
      password: null,
      court: null,
    };
  },
  methods: {
    formatDate: function (data) {
      //return moment(data).utc().format("YYYY-DD-MM HH:mm");
      return moment(data).tz("Europe/Prague").format("DD.MM.YYYY HH:mm");
      //dd.MM.yyyy HH:mm
    },
    deleteEvent() {
      axios
        .post("/api/Event/DeleteEvent", parseInt(this.password), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.toast.info("Rezervace smazána!", {
            icon: "fas fa-check",
          });
          console.log("Odesláno: ", response);
          // Wait for 2 seconds (2000 milliseconds) before reloading
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return response;
        })
        .catch((error) => {
          this.toast.error("Chybné ID!", {
            icon: "fas fa-check",
          }),
            console.error("Chyba: ", error);
        });
    },
  },
  mounted() {
    this.start = this.formatDate(this.event.start);
    this.end = this.formatDate(this.event.end);
    this.court = this.event.extendedProps.court;
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
fieldset legend {
  background: #1f497d;
  color: #fff;
  padding: 5px 10px;
  font-size: 32px;
  border-radius: 5px;
  box-shadow: 0 0 0 5px #ddd;
}
fieldset {
  font-family: sans-serif;
  border: 5px solid #1f497d;
  background: #ddd;
  border-radius: 5px;
  padding: 15px;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: transparent;
}
[data-type="submit"] .formkit-input {
  background: #ff4949;
}
</style>

<style>
.dark-mode div::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
#cancel {
  padding: 10px 10px;
  margin: 5px 5px;
  background: #ff4949;
  transition: all 100s ease-in-out;
}
#delete {
  background: #ff4949;
}
[data-family="button"].formkit-outer {
  margin-bottom: 0 !important;
}
</style>
