<template>
  <div>
    <vue-final-modal
      v-bind="$attrs"
      classes="modal-container"
      content-class="modal-content"
      name="CreateModal"
      :click-to-close="false"
    >
      <button class="modal__close" @click="closeModal">
        <font-awesome-icon icon="close" class="fa-xl"></font-awesome-icon>
      </button>
      <FormKit
        type="form"
        @submit="newEvent"
        submit-label="Vytvořit"
        incomplete-message="Zkontrolujte formulář!"
      >
        <fieldset>
          <legend>Rezervace</legend>
          <p style="margin: 6px;font-weight:600;color: #f03e3e;">Cena kurtu 120 Kč/h</p>
          <FormKit
            class="formkit-outer"
            type="text"
            name="jmeno"
            label="Jméno a
          Příjmení:"
            placeholder="Vaše jméno a příjmení"
            :validation="[['required'], ['matches', /^[A-ž]{3,} [A-ž]{3,}$/]]"
            validation-visibility="dirty"
            :validation-messages="{
              matches: 'Musí obsahovat Jméno a Příjmení!',
              required: 'Vyplňte pole!',
            }"
            v-model="title"
            :floating-label="true"
          />
          <FormKit
            name="email"
            type="email"
            label="Email:"
            validation="required|email"
            validation-visibility="dirty"
            placeholder="email@xxxx.xx"
            :validation-messages="{
              required: 'Zadejte email!',
              email: 'Použijte správný formát!',
            }"
            v-model="email"
          />
          <FormKit
            type="tel"
            label="Telefon:"
            placeholder="xxx-xxx-xxx"
            :validation="[['required'],['matches', /^[0-9]{3}[0-9]{3}[0-9]{3}$/]]"
            :validation-messages="{
              matches: 'Telefonní číslo musí být ve formátu xxx-xxx-xxx',
              required: 'Zadejte telefonní číslo!',
            }"
            validation-visibility="dirty"
            v-model="phone"
          />
          <FormKit
            type="checkbox"
            label="Podmínky"
            help="Souhlasíte s dalším zpracováním emailové adresy a telefonního čísla?"
            name="terms"
            validation="accepted"
            validation-visibility="dirty"
            :validation-messages="{
              accepted: 'Musíte souhlasit!',
            }"
          />
          <FormKit
            type="select"
            label="Kurt číslo:"
            name="court"
            v-model="courtNumber"
            :options="['1', '2']"
          />
          <label for="startDate" class="formkit-label">Začátek:</label>
          <MyDatepicker
            :disabled-time="isTimeDisabled"
            :start-time="startTime"
            :min-time="{ hours: 11}"
            :max-time="{ hours: 21}"
            no-minutes-overlay
            teleport-center
            value="moment()"
            required
            locale="cs-CZ"
            format="dd.MM.yyyy HH:mm"
            cancelText="Zrušit"
            selectText="Vybrat"
            :clearable="false"
            minutes-increment="30"
            minutes-grid-increment="10"
            type="datetime-local"
            name="startDate"
            v-model="start"
            ><template #calendar-header="{ index, day }">
              <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                {{ day }}
              </div>
            </template>
            <template #clock-icon>
              <font-awesome-icon icon="fa-solid fa-clock" />
              <h3 style="color: #fb4d3d">&nbsp;Čas</h3>
            </template>
            <template #calendar-icon>
              <font-awesome-icon icon="fa-solid fa-calendar" />
              <h3 style="color: 38b000">&nbsp;Datum</h3>
            </template>
          </MyDatepicker>
          <br />
          <label for="endDate" class="formkit-label">Konec:</label>
          <MyDatepicker
            :start-time="startTime"
            :min-time="{ hours: 11}"
            :max-time="{ hours: 21}"
            no-minutes-overlay
            teleport-center
            value="moment()"
            required
            locale="cs-CZ"
            format="dd.MM.yyyy HH:mm"
            cancelText="Zrušit"
            selectText="Vybrat"
            :clearable="false"
            type="datetime-local"
            name="endDate"
            minutes-increment="30"
            v-model="end"
            ><template #calendar-header="{ index, day }">
              <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                {{ day }}
              </div>
            </template>
            <template #clock-icon>
              <font-awesome-icon icon="fa-solid fa-clock" />
              <h3 style="color: red">&nbsp;Čas</h3>
            </template>
            <template #calendar-icon>
              <font-awesome-icon icon="fa-solid fa-calendar" />
              <h3 style="color: red">&nbsp;Datum</h3>
            </template>
          </MyDatepicker>
        </fieldset>
      </FormKit>
    </vue-final-modal>
    <ConfirmReservationModal v-if="showConfirmModal"></ConfirmReservationModal>
  </div>
</template>
<script>
// function getRandomColor() {
//   var colors = [
//     "aqua",
//     "black",
//     "blue",
//     "fuchsia",
//     "green",
//     "lime",
//     "maroon",
//     "navy",
//     "olive",
//     "orange",
//     "purple",
//     "red",
//     "silver",
//     "gray",
//     "teal",
//   ];
//   Array.prototype.getRandom = function (cut) {
//     var i = Math.floor(Math.random() * this.length);
//     if (cut && i in this) {
//       return this.splice(i, 1)[0];
//     }
//     return this[i];
//   };
//   return colors.getRandom();
// }
// function IsToday() {
//   if (moment("dd/mm/yyyy").isSame(Date.now(), "day")) {
//     return new Date().getHours();
//   }
// }
import { useToast } from "vue-toastification";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import ConfirmReservationModal from "./ConfirmReservationModal.vue";
import { VueFinalModal } from 'vue-final-modal';
import NotificaionModal from "./NotificaionModal.vue";


export default {
  setup() {
    const toast = useToast();
    // toast.success("Rezervace vytvořena!", {
    //   icon: "fas fa-check",
    // });
    return { toast};
  },
  components: {
    ConfirmReservationModal,
    VueFinalModal
  },
  data() {
    return {
      title: "",
      email: "",
      phone: "",
      start: "",
      end: "",
      courtNumber: this.courtNumber,
      //isConfirmModalVisible: false,
      confirmationCode: '',
      currentEvent: null,
      showConfirmModal: false
    };
  },
  mounted() {
    this.start = this.updateTime(this.clickedEventStartDate);
    // Add 1 hour to the start time
    //this.start = new Date(this.start.getTime() + 3600000); // 1 hour = 3600000 milliseconds
    // Calculate the end time by adding 1 hour to the start time
    this.end = new Date(this.start.getTime() + 3600000); // Add 1 hour
    //this.$refs.confirmModal.open();
  },
  methods: {
    updateTime(date) {
      const currentLuxonTime = DateTime.local();
      let updatedDate = DateTime.fromJSDate(date);

      // Kontrola, zda je poskytnuté datum alespoň 1 hodinu v budoucnosti
      const isAtLeast1HourInFuture = updatedDate.diff(currentLuxonTime, 'hours').hours >= 1;

        // Pokud není, upraví datum, aby bylo přesně 1 hodinu v budoucnosti
        if (!isAtLeast1HourInFuture) {
            updatedDate = currentLuxonTime.plus({ hours: 1 });
        }

        // Zaokrouhlení na nejbližší půlhodinu nebo celou hodinu
        const minutes = updatedDate.minute;
        if (minutes > 0 && minutes <= 30) {
            updatedDate = updatedDate.set({ minute: 30, second: 0, millisecond: 0 });
        } else if (minutes > 30) {
            updatedDate = updatedDate.plus({ hours: 1 }).set({ minute: 0, second: 0, millisecond: 0 });
        } else {
            updatedDate = updatedDate.set({ minute: 0, second: 0, millisecond: 0 });
        }

      return updatedDate.toJSDate();
    },
    
    isTimeDisabled(time) {
      // Převod času do Date objektu
      const date = new Date(time);
      const day = date.getDay();
      const hours = date.getHours();

      console.log(`Checking time: ${date.toISOString()}, Day: ${day}, Hours: ${hours}`);

      if (day === 5 && hours >= 17 && hours < 20) {
        console.log(`Disabling time: ${date.toISOString()}`);
        return true;
      }

      return false;
    },
    formatData: function (data) {
      return moment(data).format("YYYY-MM-DDTHH:mm");
    },
    getHumanDate: function (date) {
      return moment(date).format("DD.MM.YYYY, H:mm");
    },
    closeModal() {
      this.start = null;
      // Clear the clicked event start date in the store
      this.$store.commit("CLEAR_CLICKED_EVENT_START_DATE");

      // Hide the modal
      //this.$modal.hide("CreateModal");
      this.$vfm.hide("CreateModal");
    },
    updateEvent() {
      this.$store.commit("UPDATE_EVENT", {
        id: this.event.id,
        title: this.title,
        email: this.email,
        phoe: this.phone,
        start: this.start,
        end: this.end,
      });
    },
    //Vytvoření rezervace
    newEvent() {
      //this.showConfirmModal = true
      //console.log("Selected Court Number:", this.courtNumber);
      const selectedDate = moment(this.start, "YYYY-MM-DDTHH:mm");
      const currentDate = moment();
      const isFutureDate = selectedDate.isSameOrAfter(currentDate, "minute");

      if (!isFutureDate) {
        this.toast.error("Nelze vybrat minulý čas!", {
          icon: "fas fa-calendar-xmark",
        });
        return;
      }

      if (!this.start || !this.end) {
        this.toast.error("Zkontrolujte začátek a konec rezervace!", {
          icon: "fas fa-calendar-xmark",
        });
        return;
      }

      const isValidTime = moment(this.end, "YYYY-MM-DDTHH:mm").isAfter(
        moment(this.start, "YYYY-MM-DDTHH:mm")
      );

      if (!isValidTime) {
        this.toast.error("Konec rezervace musí být po začátku!", {
          icon: "fas fa-calendar-xmark",
        });
        return;
      }
      if (this.$store.getters.EVENTS.length > 0) {
        var sameTime = [];
        //naplní pole eventy, kde se čas shoduje
        this.$store.getters.EVENTS.forEach((element) => {
          var startTime = moment(element.start);
          var endTime = moment(element.end);
          //ZMĚNA
          if (
            moment(this.start).isBetween(startTime, endTime, null, "[)") || // Allow overlap at the start
            moment(this.end).isBetween(startTime, endTime, null, "(]") || // Allow overlap at the end
            (this.start >= startTime && this.end <= endTime)
          ) {
            // Check if new reservation is within an existing reservation
            sameTime.push(element);
          }
        });
        if (sameTime.length === 2) {
          let isCapacityExceeded = false;
          sameTime.forEach((elementTime) => {
            this.courtNumber = elementTime.court;
            if (this.courtNumber >= 2) {
              this.toast.error("V tento čas je kapacita již obsazena!", {
                icon: "fas fa-calendar-xmark",
              });
              isCapacityExceeded = true;
              this.courtNumber = 1;
              return; // Exit the forEach loop
            }
          });

          if (isCapacityExceeded) {
            return; // Exit the newEvent function
          }
        }
        // Check if the selected court is occupied
        console.log("Selected Court Number:", this.courtNumber);
        // Check if the selected court is occupied at the chosen time
        const isCourtOccupied = sameTime.some((elementTime) => {
          return elementTime.court.toString() === this.courtNumber.toString();
        });
        if (isCourtOccupied) {
          this.toast.error(
            "Zvolený kurt je již obsazený v tomto čase. Vyberte prosím jiný kurt.",
            {
              icon: "fas fa-calendar-xmark",
            }
          );
          return;
        }
        //Pokud jsou v poli 2 shodné časy
        if (sameTime.length == 2) {
          sameTime.forEach((elementTime) => {
            this.courtNumber = elementTime.court;
            if (this.courtNumber >= 2) {
              this.toast.error("V tento čas je kapacita již obsazena!", {
                icon: "fas fa-calendar-xmark",
              });
              return;
            }
          });
        }
        //Pokud pouze 1
        if (sameTime.length == 1) {
          var eventOverlap = this.courtNumber++;
          //parseInt(this.courtNumber) + parseInt(this.courtNumber);
          if (
            moment(this.start, "YYYY-MM-DDTHH:mm").isBefore(
              moment(this.end, "YYYY-MM-DDTHH:mm")
            )
          ) {
            //this.$vfm.hide("CreateModal").then(() => {
              this.$store.commit("ADD_EVENT", {
                id: new Date().getMilliseconds(),
                title: this.title,
                email: this.email,
                phone: this.phone,
                start: this.start,
                end: this.end,
                allDay: false,
                color: "green",
                court: eventOverlap,
              });
              this.createPost(
                this.$store.getters.EVENTS[
                  this.$store.getters.EVENTS.length - 1
                ]
              );
          } else {
            this.toast.error("Zkontrolujte datum!", {
              icon: "fas fa-calendar-xmark",
            });
            return;
          }
        }
        if (sameTime.length == 0) {
          if (
            moment(this.start, "YYYY-MM-DDTHH:mm").isBefore(
              moment(this.end, "YYYY-MM-DDTHH:mm")
            )
          ) {
            //this.$vfm.hide("CreateModal").then(() => {
              this.$store.commit("ADD_EVENT", {
                id: new Date().getMilliseconds(),
                title: this.title,
                email: this.email,
                phone: this.phone,
                start: this.start,
                end: this.end,
                allDay: false,
                color: "green",
                court: this.courtNumber,
              });
              this.createPost(
                this.$store.getters.EVENTS[
                  this.$store.getters.EVENTS.length - 1
                ]
              );
          } else {
            //console.log(moment(this.start).format("YYYY-MM-DDTHH:mm"));
            //console.log(this.end);
            this.toast.error("Zkontrolujte datum!", {
              icon: "fas fa-calendar-xmark",
            });
            return;
          }
        }
      } else {
        //První rezervace
        if (
          moment(this.start, "YYYY-MM-DDTHH:mm").isBefore(
            moment(this.end, "YYYY-MM-DDTHH:mm")
          )
        ) {
          //this.$vfm.hide("CreateModal").then(() => {
            this.$store.commit("ADD_EVENT", {
              id: new Date().getMilliseconds(),
              title: this.title,
              email: this.email,
              phone: this.phone,
              start: this.start,
              end: this.end,
              allDay: false,
              color: "green",
              court: this.courtNumber,
            });
            this.createPost(
              this.$store.getters.EVENTS[this.$store.getters.EVENTS.length - 1]
            );
        } else {
          //console.log(moment(this.start).format("YYYY-MM-DDTHH:mm"));
          //console.log(this.end);
          this.toast.error("Zkontrolujte datum!", {
            icon: "fas fa-calendar-xmark",
          });
          return;
        }
      }
    },
    // Tady musí být otevření okna pro ověření
    createPost(event) {


      axios.post("/api/EmailConfirm/send_confirmation_email", JSON.stringify(event), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.showConfirmModal = true;
          this.toast(NotificaionModal, {
            position: "top-center",
            timeout: false,
            closeOnClick: true,
            draggable: false,
            closeButton: "button",
            showCloseButtonOnHover: false,
            timeout: 10000,
            icon: false,
            rtl: false
          });
        })
        .catch((error) => {
          //console.log(moment(this.start).format("YYYY-MM-DDTHH:mm"));
          console.log("Chyba!");
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["EVENTS"]),
    ...mapGetters(["clickedEventStartDate"]),
    startTime() {
      const now = new Date();
      return { hours: now.getHours(), minutes: now.getMinutes() };
    },
  },
  props: {
    event: {
      type: Object,
    },
  },
};

</script>
<style>
[data-invalid] .formkit-inner {
  border-color: red;
  box-shadow: 0 0 0 1px red;
}

[data-complete] .formkit-inner {
  border-color: red;
  box-shadow: 0 0 0 1px #52d352;
}
[data-complete][data-type="text"] .formkit-inner::after {
  font-family: "FontAwesome";
  content: "\f058";
  display: block;
  padding: 0.5em;
  color: #52d352;
}
[data-complete][data-type="email"] .formkit-inner::after {
  font-family: "FontAwesome";
  content: "\f058";
  display: block;
  padding: 0.5em;
  color: #52d352;
}
.dp__theme_light {
  --dp-success-color: #38b000 !important;
}
.formkit-help {
  text-align: left;
  max-width: 300px;
}
</style>
<style scoped>
fieldset legend {
  background: #41a538;
  color: #fff;
  padding: 5px 10px;
  font-size: 28px;
  border-radius: 5px;
  box-shadow: 0 0 0 5px #ddd;
}
fieldset {
  font-family: sans-serif;
  border: 2px solid #000000;
  background: #ddd;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: px solid #000000;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: transparent;
}
.formkit-input {
  line-height: normal;
}
/* label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
} */
.red-color {
  color: red;
}
.dark-mode div::v-deep(.modal-content) {
  border-color: #6efaad;
  background-color: #1a202c;
}
</style>
