<template>
  <div class="container">
    <h1>Rezervace byla zadána!</h1>
    <span>Pro potvrzení rezervace je nutné zadat potvrzovací kód, který Vám byl doručen na e-mail.</span>
  </div>
</template>
<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

h1 {
  margin-bottom: 20px; /* Zmenšení mezery pod h1 */
  font-family: 'Arial', sans-serif;
  font-size: 2em;
  color: #ffffff;
}

span {
  font-family: 'Arial', sans-serif;
  font-size: 1em;
  color: #ffffff;
}
</style>