<template>
  <div>
    <vue-final-modal
      v-bind="$attrs"
      classes="modal-container"
      content-class="modal-content"
      name="ConfirmReservationModal"
      v-model="isVisible"
      :click-to-close="false"
    >
      <button class="modal__close" @click="closeModal">
        <font-awesome-icon icon="close"></font-awesome-icon>
      </button>
      <FormKit
        type="form"
        @submit="confirmReservation"
        submit-label="Potvrdit"
        incomplete-message="Zadejte potvrzovací kód!"
      >
        <fieldset>
          <legend>Potvrzení rezervace</legend>
          <FormKit
            class="formkit-outer"
            type="text"
            name="confirmCode"
            label="Zadejte potvrzovací kód z e-mailu:"
            placeholder="Kód"
            :validation="[['required'], ['length', 6]]"
            validation-visibility="dirty"
            :validation-messages="{
              required: 'Vyplňte pole!',
              length: 'Kód musí mít 6 znaků!',
            }"
            v-model="confirmationCode"
            :floating-label="true"
          />
        </fieldset>
      </FormKit>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import axios from "axios";
import { mapState } from 'vuex';
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    VueFinalModal,
  },
  data() {
    return {
      isVisible: true,
      confirmationCode: '',
    };
  },
  computed: {
    ...mapState({
      events: state => state.events
    }),
    currentEvent() {
      return this.events.length > 0 ? this.events[this.events.length - 1] : null;
    },
    email() {
      return this.currentEvent ? this.currentEvent.email : '';
    }
  },
  methods: {    
    open() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
      this.$vfm.hide("CreateModal");
    },
    confirmReservation() {
      axios.post("/api/EmailConfirm/confirm_reservation", JSON.stringify(this.confirmationCode), {
        //email: this.currentEvent.email,
        headers: {
            "Content-Type": "application/json",
          }
        })
        .then((response) => {
          this.closeModal();
          this.toast.success("Rezervace potvrzena!", {
            icon: "fas fa-check",
          });
          console.log(response);
          setTimeout(function(){ location.reload(); }, 3500);
        })
        .catch((error) => {
          console.log("Chyba při ověření kódu!", error);
          this.toast.error("Chyba při ověření kódu!", {
            icon: "fas fa-times",
          });
        });
    },
    // saveReservation() {
    //   axios.post("/api/Event/SaveEvent", JSON.stringify(this.currentEvent), {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       this.$toast.success("Rezervace vytvořena!", {
    //         icon: "fas fa-check",
    //       });
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.log("Chyba!", error);
    //     });
    // },
  },
};
</script>

<style scoped>
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: transparent;
}
fieldset legend {
  background: #41a538;
  color: #fff;
  padding: 5px 10px;
  font-size: 28px;
  border-radius: 5px;
  box-shadow: 0 0 0 5px #ddd;
}
fieldset {
  font-family: sans-serif;
  border: 2px solid #000000;
  background: #ddd;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: px solid #000000;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: transparent;
}
.formkit-input {
  line-height: normal;
}
/* label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
} */
.red-color {
  color: red;
}
.dark-mode div::v-deep(.modal-content) {
  border-color: #6efaad;
  background-color: #1a202c;
}
</style>
