//import { createApp } from 'vue'
//import App from './App.vue'

//createApp(App).mount('#app')
import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store'
import { vfmPlugin } from 'vue-final-modal'

//* FormKit
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'

//* FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

//* Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//* Date picker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
library.add(fas)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('MyDatepicker', Datepicker)
    .use(plugin, defaultConfig)
    .use(store)
    .use(vfmPlugin)
    .use(Toast, {
        timeout: 3000, position: "top-center", showCloseButtonOnHover: true, maxToasts: 5,
        newestOnTop: true
    })
    .mount('#app')