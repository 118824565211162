import { createStore } from 'vuex'

export default createStore({
  state: {
    events: [
      // { "id": 1656854718539, "title": "Karel Novák", "start": "2022-08-26T15:25", "end": "2022-07-03T18:25", "allDay": false, "color": "#7ED0B1", "email": "Testovani" }
    ],
  },
  getters: {
    EVENTS: (state) => state.events,
    clickedEventStartDate: state => state.clickedEventStartDate,
  },
  mutations: {
    ADD_EVENT: (state, event) => {
      state.events.push(event);
    },
    UPDATE_EVENT: (state, { id, title, email, phone, start, end, court }) => {
      let index = state.events.findIndex((_event) => _event.id == id);
      state.events[index].title = title;
      state.events[index].email = email;
      state.events[index].phone = phone;
      state.events[index].start = start;
      state.events[index].end = end;
      state.events[index].court = court;
    },
    SET_SELECTED_START_DATE(state, startDate) {
      state.clickedEventStartDate = startDate;
    },
    CLEAR_CLICKED_EVENT_START_DATE(state) {
      state.clickedEventStartDate = null;
    },
  },
  actions: {},
});